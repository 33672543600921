.catswipperslider {
  display: flex;
  justify-content: space-between;
  padding-inline: 1.2rem;
  /* margii */
}

.catswipperslider .swiper-button-prev::after,
.catswipperslider .swiper-button-next::after {
  z-index: 10000;
  color: #c9041a;
  opacity: 1;
  position: relative;
}
.catswipperslider .swiper-button-prev {
  left: 0rem;
  background-color: white;
  border-radius: 9px;
  height: 64px;
  width: 24px;
  transition: all ease 300ms;
}
.catswipperslider .swiper-button-next {
  background-color: white;
  border-radius: 9px;
  height: 64px;
  width: 24px;
  right: 0rem;
  transition: all ease 300ms;
}
.catswipperslider .swiper-button-prev:hover {
  background-color: #fdfdfd;
}
.catswipperslider .swiper-button-prev::after {
  font-size: 16px !important;
}
.catswipperslider .swiper-button-next::after {
  font-size: 16px !important;
}

.catswipperslider .swiper-button-next:hover {
  background-color: #fdfdfd;
}

@media (min-width: 992px) {
  .catswipperslider {
    padding-inline: 3.2rem;
  }
}
