/* Example transition effect */
.fade-enter {
  opacity: 0%;
  transform: scale(0.9);
  transition: opacity 10ms, transform 10000ms;
}

.fade-enter-active {
  opacity: 1;
  transform: scale(1);
}

.fade-exit {
  opacity: 1;
  transform: scale(1);

  transition: opacity 10ms, transform 10000ms;
}

.fade-exit-active {
  opacity: 0%;
  transform: scale(0.9);
}

.industry-button-prev {
  background-color: #ccc;
  border-radius: 9px;
  height: 64px;
  width: 24px;
  transition: all ease 300ms;
  cursor: pointer;
  color: #c9041a;
  font-size: 16px;
}
.industry-button-next {
  background-color: #ccc;
  border-radius: 9px;
  height: 64px;
  width: 24px;
  font-size: 16px;
  transition: all ease 300ms;
  cursor: pointer;
  color: #c9041a;
}

.industry-button-prev:hover {
  background-color: #ddd;
}

.industry-button-next:hover {
  background-color: #ddd;
}
