.heroSlider {
  width: 100%;
  height: 100%;
}

.heroSlider .swiper-slide {
  min-height: 100% !important;
}

.heroSlider .swiper-pagination {
  bottom: 3% !important;
}

.heroSlider .swiper-pagination-bullet {
  background-color: transparent !important;
  border: 2px solid #c9041a !important;
  width: 54px;
  height: 8px;
  border-radius: 11px;

  background-color: hsla(0, 0%, 100%, 0.2);
  border: 1px solid hsla(0, 0%, 100%, 0.4);
}

.heroSlider .swiper-pagination-bullet-active {
  background-color: #c9041a !important;
}

.zoom {
  transition: transform 0.5s ease;
}

.zoom.zoomed {
  animation: zoomInOut 3.8s alternate;
}

@keyframes zoomInOut {
  0% {
    transform: scale(1.15);
  }
  100% {
    transform: scale(1);
  }
}
.hero-section {
  height: 100dvh;
  width: 100%;
}

.heroSlider-slider-parent {
  height: 100dvh;
  width: 100%;
}

.content-parent {
  height: 100dvh;
  width: 100%;
}

.image-parent {
  height: 100dvh;
  width: 100%;
}

.hero-image {
  height: 100dvh;
  width: 100%;
  object-fit: cover;
}

/* hero */
@media screen and (min-width: 992px) {
  .hero-section {
    height: 100vh;
    width: 100%;
  }

  .heroSlider-slider-parent {
    height: 100dvh;
    width: 100%;
  }

  .content-parent {
    height: 100dvh;
    width: 100%;
  }

  .image-parent {
    height: 100dvh;
    width: 100%;
  }

  .hero-image {
    height: 100vh;
    width: 100%;
  }
}
