.Container {
  width: min(100% - 3.6rem, 120rem);
  margin-inline: auto;
}
.services-slider-container {
  width: min(100% - 3.6rem, 120rem);
  margin-inline: auto;
}

@media (min-width: 768px) {
  .Container {
    width: min(100% - 6.4rem, 120rem);
  }
  .services-slider-container {
    width: min(100% - 6.4rem, 120rem);
  }
}

@media (min-width: 1440px) {
  .Container {
    width: min(100% - 24.6rem, 120rem);
  }

  .services-slider-container {
    width: min(100% - 16.6rem, 120rem);
  }
}
@media (min-width: 1920px) {
  .Container {
    width: min(100% - 30rem, 120rem);
  }

  .services-slider-container {
    width: min(100% - 24.6rem, 120rem);
  }
}
