.feature-slider .swiper-button-prev::after,
.feature-slider .swiper-button-next::after {
  z-index: 10000;
  color: white;
  opacity: 1;
  position: relative;
}

.feature-slider .swiper-button-prev {
  left: 2rem;
  background-color: #ffffff0d;
  border-radius: 9px;
  height: auto;
  transition: all ease 300ms;
  height: 4rem;
}
.feature-slider .swiper-button-prev:hover {
  background-color: #ffffff2e;
}
.feature-slider .swiper-button-prev::after {
  font-size: 22px !important;
}
.feature-slider .swiper-button-next::after {
  font-size: 22px !important;
}
.feature-slider .swiper-button-next {
  background-color: #ffffff0d;

  border-radius: 9px;
  height: auto;
  right: 2rem;
  height: 4rem;
  transition: all ease 300ms;
}

.feature-slider .swiper-button-next:hover {
  background-color: #ffffff2e;
}

.feature-slider {
  display: flex;
  align-items: stretch;
  min-height: 100%;
}
.feature-slider .swiper-wrapper {
  display: flex;
  align-items: stretch;
  min-height: 100%;
  height: auto !important;
  flex-grow: 1;
}

.feature-slider .swiper-slide {
  display: flex;
  align-items: stretch;
  min-height: 100%;
  flex-grow: 1;
}

.panel-background {
  background-size: cover;
}

.feature-slider .swiper-pagination {
  margin-bottom: 10px;
}

.feature-slider .swiper-pagination-bullet {
  width: 10px;
  height: 10px;
}

.feature-slider .swiper-pagination-bullet-active {
  background-color: "#c9041a" !important;
}

@media screen and (min-width: 1400px) {
  .panel-background {
    background-size: cover;
  }
}
@media screen and (min-width: 1550px) {
  .panel-background {
    background-size: cover;
    background-position-x: 0px;
  }
}
