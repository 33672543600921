.MobileBtnBox > span {
  display: block;
  background-color: #c9041a;
  border-radius: 50%;
  width: 5.5px;
  height: 5.5px;
  transition: all ease 0.3s;
  z-index: 1000;
}

.MobileBtnBox.isActive > span:nth-child(2) {
  display: none;
}

.MobileBtnBox.isActive > span {
  width: 1.4rem;
  height: 2px;
  border-radius: 0;
  background-color: white;
}

.MobileBtnBox.isActive > span:first-child {
  rotate: 135deg;
}

.MobileBtnBox.isActive > span:last-child {
  rotate: -135deg;
  position: absolute;
  /* margin-bottom: 10px; */
}

/* Add this to your CSS file */
.expanded-ul {
  position: relative;
  list-style: disc;
  opacity: 1;
  max-height: 1000px;
  transition: max-height 0.3s ease; /* Add transition effect */
}

.collapsed-ul {
  position: absolute;
  max-height: 0;
  opacity: 0;
  overflow: hidden;
  transition: max-height 0.3s ease; /* Add transition effect */
}

.item-icon {
  opacity: 0;
  translate: -40% 0;
  transition: all ease 0.3s;
}
.item-list:hover .item-icon {
  opacity: 1;
  translate: 0% 0;
}
