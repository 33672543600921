.LatesNewsSlider .swiper-button-prev::after,
.LatesNewsSlider .swiper-button-next::after {
  z-index: 10000;
  color: #c9041a;
  opacity: 1;
  position: relative;
}

.LatesNewsSlider .swiper-button-prev {
  left: 1rem;
  /* left: 8rem; */
  background-color: #ccc;
  border-radius: 9px;
  height: 64px;
  width: 24px;
  transition: all ease 300ms;
}
.LatesNewsSlider .swiper-button-next {
  background-color: #ccc;
  border-radius: 9px;
  height: 64px;
  width: 24px;
  right: 1rem;
  /* right: 8rem; */
  transition: all ease 300ms;
}

.LatesNewsSlider .swiper-button-prev:hover {
  background-color: #ddd;
}
.LatesNewsSlider .swiper-button-prev::after {
  font-size: 16px !important;
}
.LatesNewsSlider .swiper-button-next::after {
  font-size: 16px !important;
}

.LatesNewsSlider .swiper-button-next:hover {
  background-color: #ddd;
}
