@tailwind base;
@tailwind components;
@tailwind utilities;

/* @font-face {
  font-family: "Segu";
  src: url("./Asset/font/SEGOEUISL.TTF") format("ttf");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Segu-medium";
  src: url("./Asset/font/SEGUISB-medium.TTF") format("ttf");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Segu-bold";
  src: url("./Asset/font/SEGOEUIB-bold.TTF") format("ttf");
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: "Segu-black";
  src: url("./Asset/font/SEGUIBL-black.TTF") format("ttf");
  font-weight: 900;
  font-style: normal;
} */

@font-face {
  font-family: "SegoeUI"; /* Give it a unique and meaningful name */
  src: url("./Asset/font/SEGOEUI.TTF") format("truetype"); /* Use 'truetype' instead of 'ttf' */
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "SegoeUI"; /* Give it a unique and meaningful name */
  src: url("./Asset/font/SEGOEUIB-bold.TTF") format("truetype"); /* Use 'truetype' instead of 'ttf' */
  font-weight: 800;
  font-style: normal;
}

body {
  font-family: "SegoeUI", sans-serif; /* Apply the custom font, with a fallback to a common font */
  font-weight: 400;
  font-style: normal;
}

.left-container {
  margin-left: calc(3.6rem / 2);
}
.right-container {
  margin-right: calc(3.6rem / 2);
}

.custom-container {
  width: min(100% - 3.6rem, 120rem);
  margin-inline: 3.6rem;
  border: 1px solid red;
}

.industry-container-left {
  margin-left: calc(3.6rem / 2);
  margin-right: calc(3.6rem / 2);
}
.industry-container-right {
  margin-left: calc(3.6rem / 2);
  margin-right: calc(3.6rem / 2);
}
@media (min-width: 768px) {
  .left-container {
    margin-left: calc(6.4rem / 2);
  }
  .right-container {
    margin-right: calc(6.4rem / 2);
  }

  .custom-container {
    width: min(100% - 16.4em, 120rem);
  }

  .industry-container-left {
    margin-left: calc(6.4rem / 2);
    margin-right: calc(6.4rem / 2);
  }

  .industry-container-right {
    margin-left: calc(6.4rem / 2);
    margin-right: calc(6.4rem / 2);
  }
}
@media (min-width: 1200px) {
  .left-container {
    margin-left: calc(24.6rem / 2);
  }
  .right-container {
    margin-right: calc(24.6rem / 2);
  }

  .industry-container-left {
    margin-left: calc(12.2rem / 2);
    margin-right: 0;
  }

  .industry-container-right {
    margin-left: 0;
    margin-right: calc(12.2rem / 2);
  }
}

.list-grid {
  display: grid;
  grid-template-columns: minmax(0, 1fr);
}

.about-class {
  background-position: top -100px right 0px;
  background-repeat: no-repeat;
  background-size: cover;
}
.background-class {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.full-background-class {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

/*  */
.custom-content a {
  color: blue;
  text-decoration: underline;
}

.custom-content h2 {
  font-size: 2rem;
  font-weight: bold;
  color: #c9041a;
}
.custom-content h3 {
  font-size: 1.6rem;
  font-weight: medium;
  /* color: #c9041a; */
}
.custom-content h4 {
  font-size: 1.2rem;
  font-weight: bold;
}

.custom-content ul {
  list-style: disc;
  list-style-position: outside;
  padding-left: 20px;
}

.custom-content ul ul {
  list-style: disc;
}
