.solution-slider {
  display: flex;
  justify-content: space-between;
  padding-inline: 1rem;
}

.solution-slider .swiper-button-prev::after,
.solution-slider .swiper-button-next::after {
  z-index: 10000;
  color: #c9041a;
  opacity: 1;
  position: relative;
}
.solution-slider .swiper-button-prev {
  left: 0rem;
  background-color: #ccc;
  border-radius: 9px;
  height: 64px;
  width: 24px;
  transition: all ease 300ms;
}
.solution-slider .swiper-button-next {
  background-color: #ccc;
  border-radius: 9px;
  height: 64px;
  width: 24px;
  right: 0rem;
  transition: all ease 300ms;
}
.solution-slider .swiper-button-prev:hover {
  background-color: #ddd;
}
.solution-slider .swiper-button-prev::after {
  font-size: 16px !important;
}
.solution-slider .swiper-button-next::after {
  font-size: 16px !important;
}

.solution-slider .swiper-button-next:hover {
  background-color: #ddd;
}
