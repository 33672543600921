.solution-prev-button {
  background-color: #ccc;
  border-radius: 9px;
  height: 64px;
  width: 24px;
  transition: all ease 300ms;
  cursor: pointer;
  color: #c9041a;
  font-size: 16px;
}
.solution-next-button {
  background-color: #ccc;
  border-radius: 9px;
  height: 64px;
  width: 24px;
  font-size: 16px;
  transition: all ease 300ms;
  cursor: pointer;
  color: #c9041a;
}

.solution-prev-button:hover {
  background-color: #ddd;
}

.solution-next-button:hover {
  background-color: #ddd;
}
