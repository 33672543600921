.services-slider.swiper {
  height: 100%;
  position: relative;
}

.services-slider .swiper-button-prev::after,
.services-slider .swiper-button-next::after {
  z-index: 10000;
  color: #c9041a;
  opacity: 1;
  position: relative;
}

.swiper-button-prev {
  left: 0rem;
  /* left: 8rem; */
  background-color: #ccc;
  border-radius: 9px;
  height: 64px;
  width: 24px;

  transition: all ease 300ms;
}
.swiper-button-next {
  background-color: #ccc;
  border-radius: 9px;
  height: 64px;
  width: 24px;
  right: 0rem;
  /* right: 8rem; */
  transition: all ease 300ms;
}

.services-slider .swiper-button-prev:hover {
  background-color: #ddd;
}
.services-slider .swiper-button-prev::after {
  font-size: 16px !important;
}
.services-slider .swiper-button-next::after {
  font-size: 16px !important;
}

.services-slider .swiper-button-next:hover {
  background-color: #ddd;
}
